$(document).ready(function () {
    $('.scroll').click(function (e) {
        var $this = $(this);
        var href = $this.attr('href');
        if ($(href).length) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $(href).offset().top
            }, 2000);
        }
    })
}); 
